.home {
  text-align: center;
  /*background: repeating-linear-gradient(
  45deg,
  #000,
  #000 40px,
  #fff 80px 
  ); */
}

p {
  padding: .5rem 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app_box-text {
  margin-top: 1rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profile-pic {
  max-height: 20rem;
  max-width: 20rem;
  border-radius: 50%;

  @media (max-width: 480px) {
    max-height: 15rem;
    max-width: 15rem;
  }
}

.page__container {
  padding: 0 1rem;
  margin: 0 6rem;

  @media (max-width: 480px) {
    margin: 0;
  }
}

.app_box-text__paragraph {
  padding: .5rem 0;
}

.link {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.navbar__container {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
