.home {
  text-align: center;
  /*background: repeating-linear-gradient(
  45deg,
  #000,
  #000 40px,
  #fff 80px 
  ); */
}

.home_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-left: 5%;
  padding-right: 5%;
  margin: 2rem 4rem 0 4rem;

  @media (max-width: 480px) {
    margin: 4rem 0 0 0;
  }
}

.home_icon-list {
  padding: .5rem .75rem;
  display: flex;
  justify-content: space-around;

  .icon {
    text-decoration: none;
    color: #000;

  }
}

.home_link {
  color: #000;
}


li {
  list-style-type: none;
  
  &:not(:last-child) {
    margin-right: .5rem;
  }
}
