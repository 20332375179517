#image-modal__container {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0,0,0,0.75);
  top: 0;
  left: 0;
  bottom: 100vh;
  display: flex;
}

#image-modal__image {
  @media (max-width: 480px) {
    max-height: 60%;
    max-width: 80%;
  }

  z-index: 2;
  opacity: 1;
  max-height: 85%;
  max-width: 85%;
  margin: auto;

}