.preview__container {
  margin-bottom: 1rem;
}

.preview__image {
  width: 100%;
}

.preview__title-date-line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
