.portfolio-item-gallery__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.portfolio-item-gallery__image {
  flex: 31%;
  max-width: 29%;
  padding: .3rem;
}